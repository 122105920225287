import React from 'react';
import { Utils } from '../../resources/Utils';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { ClientGridController } from '../controller/ClientGridController';
import { withRouter } from 'react-router-dom';
import ClientFormComponent from '../../ClientForm/component/ClientFormComponent';
import {
	Edit,
	Home,
	ArrowLeft,
	ArrowRight,
	ToggleOff,
	ToggleOn,
	ArrowForward,
	Search
} from '@material-ui/icons';
import { Verify } from '../../utils/Verify';
import BusinessSelector from '../../resources/BusinessSelector';

class ClientGridComponent extends React.Component {
	constructor(props) {
		super(props);
		this.clientGridController = new ClientGridController();
		this.verify = new Verify();
		this.utils = new Utils();
		this.state = {
			tableData: {
				page: 0,
				limit: 8,
				text: '',
				column: 'default'
			},
			page: 1,
			cardValue: 0,
			data: {
				data: [],
				total: 0
			},
			form: {
				id: 0,
				name: '',
				surname: '',
				phone: 0,
				birthdate: '',
				email: '',
				status: 0
			},
			filterClients: {
				data: [],
				total: 0
			},
			charging: 'no'
		};
	}

	async componentDidMount() {
		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}
		await this.loadData();
	}

	handleFilterButtonClick = () => {
		const inputText = document.getElementById('busqueda In').value;
		const selectedCriteria = document.getElementById('criterio').value;

		if (selectedCriteria === 'default' || inputText.trim() === '') {
			Utils.swalError(
				'Por favor, selecciona un criterio y escribe un texto para buscar.'
			);
			return;
		}

		this.setState(
			{
				tableData: {
					...this.state.tableData,
					column: selectedCriteria,
					text: inputText,
					page: 0
				},
				filterClients: {
					...this.state.filterClients,
					data: []
				}
			},
			() => {
				this.loadData(true);
			}
		);
	};

	reload() {
		window.location.reload(false);
	}

	async loadData(change) {
		this.setState({ charging: 'yes' }, async () => {
			const body = await this.clientGridController.findFilter(
				this.state.tableData
			);
			let pagAux = this.state.page;

			if (change) {
				pagAux = 1;
			}

			this.setState({
				data: body,
				filterClients: body,
				page: pagAux,
				charging: 'no'
			});
		});
	}

	loaderFn = async () =>
		await this.loadData().then((value) => {
			this.setState({ data: value });
			this.setState({ filterClients: value });
		});

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	getNext = async (event) => {
		event.preventDefault();

		let col = 'default';
		if (document.getElementById('busqueda In').value !== '') {
			col = document.getElementById('criterio').value;
		}

		this.setState(
			{
				page: this.state.page + 1,
				tableData: {
					...this.state.tableData,
					page: this.state.tableData.page + 1,
					col,
					text: document.getElementById('busqueda In').value
				},
				filterClients: {
					...this.state.filterClients,
					data: []
				}
			},
			() => {
				this.loadData();
			}
		);
	};

	getPrev = async (event) => {
		event.preventDefault();

		let col = 'default';
		if (document.getElementById('busqueda In').value !== '') {
			col = document.getElementById('criterio').value;
		}

		if (this.state.tableData.page > 0) {
			this.setState(
				{
					page: this.state.page - 1,
					tableData: {
						...this.state.tableData,
						page: this.state.tableData.page - 1,
						column: col,
						text: document.getElementById('busqueda In').value
					},
					filterClients: {
						...this.state.filterClients,
						data: []
					}
				},
				() => {
					this.loadData();
				}
			);
		} else {
			Utils.swalSuccess('No se puede obtener datos previos.');
		}
	};

	changeStateFinal = (data) => {
		data.personVO.birthdate = this.parseDate(data.personVO.birthdate);
		this.setState({ cardValue: data.id, form: data });
	};

	filtrar = (e) => {
		let criterio = '';
		if (document.getElementById('criterio').value !== 'default') {
			if (document.getElementById('busqueda In').value === '') {
				criterio = 'default';
			} else {
				criterio = document.getElementById('criterio').value;
			}
			this.setState(
				{
					page: 0,
					tableData: {
						...this.state.tableData,
						column: criterio,
						text: document.getElementById('busqueda In').value,
						page: 0
					},
					filterClients: {
						...this.state.filterClients,
						data: []
					}
				},
				() => {
					this.loadData(true);
				}
			);
		} else {
			if (document.getElementById('busqueda In').value !== '') {
				Utils.swalError('Seleccione un criterio primero!');
			}
		}
	};

	renderBody() {
		console.log(this.state.filterClients);
		return this.state.filterClients.data.map((d) => (
			<tr key={d.id}>
				<td>{d.personVO.name}</td>
				<td>{d.personVO.surname}</td>
				<td>{d.personVO.phone}</td>
				<td>{this.parseDate(d.personVO.birthdate)}</td>
				<td>{d.email}</td>
				<td>
					<Edit onClick={() => this.changeStateFinal(d)}> Editar</Edit>
				</td>
				<td>
					{d.status === 0 ? (
						<ToggleOff
							onClick={() =>
								this.active(d.id, d.personVO.name + ' ' + d.personVO.surname)
							}
						>
							{' '}
							Activar
						</ToggleOff>
					) : (
						<ToggleOn
							color='primary'
							onClick={() =>
								this.delete(d.id, d.personVO.name + ' ' + d.personVO.surname)
							}
						>
							{' '}
							Desactivar
						</ToggleOn>
					)}
				</td>
			</tr>
		));
	}

	parseDate = (date) => {
		const cadena =
			date.substring(8, 10) +
			'-' +
			date.substring(5, 7) +
			'-' +
			date.substring(0, 4);
		return cadena;
	};

	renderCard() {
		return (
			<div className='container-fluid h-100 py-3'>
				<div className='row justify-content-center h-100'>
					<div className='col-lg-12 align-self-center'>
						<div
							className='card shadow bg-dark text-white'
							style={{ minHeight: '75%' }}
						>
							<div className='card-header'>
								<h4 className='text-center'>Clientes</h4>
							</div>

							<div className='my-3 ml-3 col-sm-12 col-md-12 col-lg-12'>
								<div className='row'>
									<div className='col-11'>
										<div className='input-group'>
											<div className='col-10 pr-0'>
												<input
													type='text'
													className='form-control'
													id='busqueda In'
													placeholder='Ingrese su criterio a buscar'
													aria-describedby='busqueda'
													required
												/>
											</div>

											<div className='col-2 p-0 m-0'>
												<div className='input-group-prepend'>
													<span className='input-group-text' id='busqueda'>
														<Search />
													</span>
												</div>
											</div>
										</div>
										<div className='col-lg-3 col-sm-6 col-md-4 mt-3'>
											<select
												defaultValue='default1'
												className='form-select'
												aria-label='Default select example'
												id='criterio'
											>
												<option value='default'>Seleccione un criterio</option>
												<option value='name'>Nombre</option>
												<option value='surname'>Apellido</option>
												<option value='phone'>Teléfono</option>
												<option value='email'>Email</option>
											</select>
										</div>
									</div>
								</div>
							</div>

							<div className='card-body'>
								{sessionStorage.getItem('role') !== 'sudosu' ? (
									<></>
								) : (
									<div className='row'>
										<div className='col-9 col-xl-9 col-lg-9 col-md-9'>
											<BusinessSelector />
										</div>
										<div className='col-3 col-xl-3 col-lg-3 col-md-3'>
											<button
												className='btn btn-primary d-flex align-items-center justify-content-center'
												onClick={this.handleFilterButtonClick}
												title='Filtrar clientes'
											>
												<ArrowForward style={{ color: 'white' }} />
											</button>
										</div>
									</div>
								)}
								<div className='container-fluid'>
									{this.state.charging === 'no' ? (
										<>
											{this.state.filterClients.data.length !== 0 ? (
												<div className='row justify-content-center'>
													<div className='table-responsive py-3'>
														<table className='table table-dark table-striped '>
															<thead className='thead-dark'>
																<tr>
																	<th>Nombre</th>
																	<th>Apellido</th>
																	<th>Teléfono</th>
																	<th>Fecha de Nacimiento</th>
																	<th>Correo</th>
																	<th>Editar</th>
																	<th>Estado</th>
																</tr>
															</thead>
															<tbody>{this.renderBody()}</tbody>
														</table>
														<div className='container-fluid'>
															<div className='row'>
																<div className='col-9 align-content-end'>
																	Página: {this.state.page} de{' '}
																	{Math.ceil(this.state.data.total / 8)}
																</div>
																<div className='col-1'>
																	{this.state.tableData.page === 0 ? (
																		<></>
																	) : (
																		<ArrowLeft onClick={this.getPrev} />
																	)}
																</div>
																<div className='col-1'>
																	{this.state.data.total >
																	this.state.tableData.page * 8 + 8 ? (
																		<ArrowRight onClick={this.getNext} />
																	) : (
																		<></>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											) : (
												<div className='row align-items-center justify-content-center p-4'>
													<div className='col-6 text-center'>
														<h4>Parece que no hay clientes.</h4>
														<h5>
															Prueba a asignar o insertar algún cliente o
															ingresar un criterio de busqueda distinto.
														</h5>
													</div>
												</div>
											)}
										</>
									) : (
										<></>
									)}
								</div>
							</div>
							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	delete = async (id, name) => {
		Utils.swl({
			title: '¿Desea desactivar el cliente ' + name + '?',
			showCancelButton: true,
			confirmButtonText: `Desactivar`,
			cancelButtonText: `Cancelar`
		}).then((result) => {
			if (result.isConfirmed) {
				this.deleteClient(id);
			}
		});
	};

	async deleteClient(id) {
		await this.clientGridController.delete(id);
		this.reload();
	}

	active = async (id, name) => {
		Utils.swl({
			title: '¿Desea Activar el Cliente ' + name + '?',
			showCancelButton: true,
			confirmButtonText: `Activar`,
			cancelButtonText: `Cancelar`
		}).then((result) => {
			if (result.isConfirmed) {
				this.activeClient(id);
			}
		});
	};

	async activeClient(id) {
		await this.clientGridController.active(id);
		this.reload();
	}

	render() {
		return (
			<>
				{this.state.cardValue < 1 ? (
					<div>{this.renderCard()}</div>
				) : (
					<div>{<ClientFormComponent form={this.state.form} />} </div>
				)}
			</>
		);
	}
}

export default withRouter(ClientGridComponent);
